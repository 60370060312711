body {
  --slack-color: #3f0f40;
  overflow: hidden;
}

.app__body {
  display: flex;
  height: 100vh;
}

.welcome {
  text-align: center;
  padding: 150px;
}