.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: var(--slack-color);
  color: white;
}

.header__left {
  flex: 0.3;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.header__middle {
  flex: 0.6;
  display: flex;
  align-items: center;
  padding-left: 100px;
}