.chatInput {
  border-radius: 20px;
}

.chatInput > form {
  position: relative;
  display: flex;
  justify-content: center;
}

.chatInput > form > input {
  position: fixed;
  bottom: 30px;
  width: 60%;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 20px;
}

.chatInput > form > button {
  display: none !important;
}